import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UrlEnum } from 'src/app/shared/enum/url.enum';
import { UtilitiesService } from '../../utilities/utilities.service';
import { HttpRequestService } from '../../http-request/http-request.service';
import { LocalStorageService } from '../../local-storage/local-storage.service';
import { CorporateEmployeeProfileModel } from 'src/app/shared/model/corporate/corporate-employee-profile.model';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {
  private sessionUser: any;
  private corporateEmployeeApi = `${UrlEnum.SHOHAY_CORPORATE}/employee`;

  constructor(
    readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService,
    private readonly localStorageService: LocalStorageService
  ) {}

  public getEmployeeDashboard(): Observable<any> {
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(
        `${this.corporateEmployeeApi}/get-employee-dashboard-summary/${this.sessionUser.id}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeDashboardWithId(id:string): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.corporateEmployeeApi}/get-employee-dashboard-summary/${id}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeDetails(): Observable<any> {
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(`${this.corporateEmployeeApi}/get-by-user/${this.sessionUser.id}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeOfCompany(): Observable<any> {
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(`${this.corporateEmployeeApi}/get-by-company/${this.sessionUser.company_id}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeEngagementSummary(): Observable<any> {
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(
        `${this.corporateEmployeeApi}/get-engagement-summary/${this.sessionUser.id}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeCampaignSummary(): Observable<any> {
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(
        `${this.corporateEmployeeApi}/get-campaign-dashboard-summary/${this.sessionUser.id}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeProfile(): Observable<any> {
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(
        `${this.corporateEmployeeApi}/get-employee-profile/${this.sessionUser.id}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeProfileWithId(id:string): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.corporateEmployeeApi}/get-employee-profile/${id}`
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public updateCorporateEmployeeProfile(
    profileBody: CorporateEmployeeProfileModel,
    employeeId: string
  ): Observable<any> {
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .patch(
        `${this.corporateEmployeeApi}/update/${employeeId}`,
        profileBody
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
