import { UtilitiesService } from './../../utilities/utilities.service';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UrlEnum } from 'src/app/shared/enum/url.enum';
import { HttpRequestService } from '../../http-request/http-request.service';
import { CorporateCampaignModel } from 'src/app/shared/model/corporate/corporate-campaign.model';
import { CorporateDonationModel } from 'src/app/shared/model/corporate/corporate-donation.model';
import { LocalStorageService } from '../../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class CorporateCampaignService {
  private corporateCampaignUrl = `${UrlEnum.SHOHAY_CORPORATE}/corporate-campaign`;
  private corporateDonationUrl = `${UrlEnum.SHOHAY_CORPORATE}/corporate-donation`;
  private corporateDonorUrl = `${UrlEnum.SHOHAY_CORPORATE}/corporate-donor`;
  private sessionUser: any;

  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  public getCorporateCampaignsByCompany(companyId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.corporateCampaignUrl}/get-campaign-by-company/${companyId}`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((campaigns: any[]) =>
          campaigns.map((campaign: any) => ({
            ...campaign,
            cover_img_vdo: this.utilitiesService.jsonParse(
              campaign.cover_img_vdo
            ),
          }))
        ),
        map((x: any[]) =>
          x.sort(
            this.utilitiesService.dynamicSortWithDate('created_at', 'desc')
          )
        )
      );
  }

  public getCorporateCampaignDetails(campaignId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.corporateCampaignUrl}/get-by-id/${campaignId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createCorporateCampaign(
    campaignBody: CorporateCampaignModel
  ): Observable<any> {
    return this.httpRequestService
      .post(`${this.corporateCampaignUrl}/create`, campaignBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public startCorporateCampaign(
    campaignId: string,
    startDate: string
  ): Observable<any> {
    return this.httpRequestService
      .patch(`${this.corporateCampaignUrl}/start-campaign/${campaignId}`, {
        start_date: startDate,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public stopCorporateCampaign(
    campaignId: string,
    endDate: string
  ): Observable<any> {
    return this.httpRequestService
      .patch(`${this.corporateCampaignUrl}/stop-campaign/${campaignId}`, {
        end_date: endDate,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public deleteCampaign(campaignId: string): Observable<any> {
    return this.httpRequestService
      .delete(`${this.corporateCampaignUrl}/delete-by-id/${campaignId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public createDonate(donationBody: CorporateDonationModel): Observable<any> {
    return this.httpRequestService
      .post(`${this.corporateDonationUrl}/create`, donationBody)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getCorporateDonorLeaderboard(companyId: string): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.corporateDonorUrl}/get-leaderboard-by-company/${companyId}?size=100`
      )
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any[]) =>
          x.map((item: any) => ({
            ...item,
            profile_img: this.utilitiesService.jsonParse(item.profile_img),
          }))
        )
      );
  }

  public getCampaignSummaryDashboard(campaignId:string){
    this.sessionUser = this.localStorageService.getSessionUser();
    return this.httpRequestService
      .get(`${this.corporateCampaignUrl}/get-dashboard?company_id=${this.sessionUser.company_id}&id=${campaignId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getDonationAnalytics(campaignId:string,end_date:string){
    return this.httpRequestService
      .get(`${this.corporateCampaignUrl}/get-donation-analytics?id=${campaignId}&end_date=${end_date}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getEmployeeParticipating(campaignId:string,end_date:string){
    return this.httpRequestService
      .get(`${this.corporateCampaignUrl}/get-employee-participating?id=${campaignId}&end_date=${end_date}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
